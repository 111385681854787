import React, { useState } from 'react';
import useAuth from 'contexts/auth/auth.context';
import { Badge } from 'react-bootstrap';
import { IS_BETA_RELEASE } from '../../constants/constants';
import {
  Page,
  Sidebar,
  SidebarContent,
  SidebarHelp,
  Layout,
  Wrapper,
} from './Authentication.style';
import { Title } from 'styles/Global';
import SignIn from 'components/SignIn/SignIn';
import ForgotPassword from 'components/ForgotPassword/ForgotPassword';
import Brand from 'components/Brand/Brand';
import { useWindowSizeDeviceType } from 'helper/useWindowSize';
import Mixpanel from 'helper/Mixpanel';

const ContactUs = ({ handleLiveChat }) => {
  return (
    <SidebarHelp.Wrapper>
      <SidebarHelp.Links>
        <SidebarHelp.Left>
          <a onClick={handleLiveChat}>Live Chat</a>
          <span>•</span>
          <a href="tel:+442033895255">0203 389 5255</a>
        </SidebarHelp.Left>
        {/* <SidebarHelp.Right>
          <a href="#">FAQs</a>
        </SidebarHelp.Right> */}
      </SidebarHelp.Links>
    </SidebarHelp.Wrapper>
  );
};

const Authentication: React.FC = () => {
  const { tablet } = useWindowSizeDeviceType();
  const { authState } = useAuth();
  const { form } = authState;

  const handleLiveChat = (e: any) => {
    Mixpanel.track('open_chat');
    // @ts-ignore
    window.Intercom('show');
  };

  return (
    <Page>
      <Sidebar>
        <SidebarContent>
          <Brand white />
          <Title>Welcome to Partner Centre</Title>
          <p>Manage your online store with Devo.</p>
        </SidebarContent>
        {!tablet && <ContactUs handleLiveChat={handleLiveChat} />}
      </Sidebar>
      <Layout>
        <Wrapper>
          {form === 'SIGNIN' && <SignIn />}
          {form === 'FORGOT' && <ForgotPassword />}
        </Wrapper>
      </Layout>
      {tablet && <ContactUs handleLiveChat={handleLiveChat} />}
    </Page>
  );
};

export default Authentication;
